import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io } from 'socket.io-client';
import { ApiService } from './api.service';
import { InactivityService } from './inactivity.service';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  private emitSync = new Subject<any>();
  listenSync = this.emitSync.asObservable();

  private socket;
  private status = false;
  private statusText = 'Desconectado';
  private reason = 'none';
  private session = '';
  private username = '';
  private perfil = '';
  private useremail = '';
  private timeout;

  constructor(
    private api: ApiService,
    private inactivityService: InactivityService
  ) { }

  connect(noalert?) {
    if (this.status && this.api.getUrl('io') == this.socket?.io?.uri) return
    let url = this.api.getUrl('io');
    this.socket = io(url);
    this.startListeners(noalert);
  }

  emit(endpoint, data) {
    if (!this.socket) { return }
    this.socket.emit(endpoint, data);
  }

  getSocket() {
    return {
      emit: (endpoint, data) => {
        if (!this.socket) { return }
        this.socket.emit(endpoint, data);
      },
      on: (endpoint, callback) => {
        if (!this.socket) { return }
        this.socket.on(endpoint, callback)
      }
    }
  }

  setSession(username, session, useremail, perfil, timeSession) {
    this.username = username;
    this.perfil = perfil;
    this.session = session;
    this.useremail = useremail;
    this.emit('registrar', { session: this.session, username: this.username, useremail: this.useremail });
    this.inactivityService.stopMonitoring();
    this.inactivityService.startMonitoring(timeSession,() => {
      this.status = false;
      this.statusText = 'Desconectado por inatividade!';
      this.updSync({ alert: true, statusColor: 'danger', disconnectForce:true, click:false });
    });
  }

  updSync(data?) {
    let baseData = {
      type: 'status',
      status: this.status,
      statusText: this.statusText
    };
    var finalData = Object.assign(baseData, (data || {}));
    console.log("🚀 ~ SyncService ~ updSync ~ finalData:", finalData)
    this.emitSync.next(finalData);
  }

  startListeners(noalert?) {
    this.socket.on('connect', () => {
      // console.log('Conectado > ', this.socket.io.uri);
      this.socket.emit('setRoom', { room: 'multi-' + location.hostname });
      this.socket.emit('msg', 'teste localhost');
      this.status = true;
      this.statusText = 'Conectado';
      this.reason = 'none';
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
        console.log('Limpando timeout');
        if(!noalert){
          this.updSync({ alert: false, statusColor: 'success' });
        }
      } else {
        if(!noalert){
          this.updSync({ alert: true, statusColor: 'success' });
        }
      }
    });
    window.checkUsers = () => {
      this.emit('listUsersOnline', { username: this.username });
    }
    this.socket.on('usersOnline', (data) => {

    });
    this.socket.on('checkUsersOnline', () => {
      this.emit('userOnline', { username: this.username });
    });
    this.socket.on('disconnect', (reason) => {
      this.status = false;
      if (this.reason != 'self') {
        if (this.reason == 'none') {
          this.statusText = 'Desconectado por falta de conexão';
        } else {
          this.statusText = 'Sem conexão com o servidor';
        }
        console.log('gerando timeout');
        this.updSync({ alert: false, statusColor: 'warning' });
        this.timeout = setTimeout(() => {
          clearTimeout(this.timeout);
          this.timeout = null;
          this.updSync({ alert: true, statusColor: 'danger' });
        }, 1000 * 10);
      } else {
        this.statusText = 'Desconectado';
        // this.updSync()
      }

    });
    this.socket.on('server', (data) => {
      this.reason = 'server';
    });
    this.socket.on('sessionOff', (data) => {
      if (data.username == this.username && data.sessionOff == this.session) {
        this.status = false;
        this.statusText = 'Você foi deslogado';
        this.updSync({ alert: true, statusColor: 'danger' });
      }
    });
    this.socket.on('notify', (data) => {

      let statusPara = false;
      if (typeof data.para == 'string' && data.para == 'Todos') {
        statusPara = true;
      } else if (typeof data.para != 'string' && data.para.tipo == 'Todos') {
        statusPara = true;
      } else if (typeof data.para == 'string' && data.para.includes(this.username)) {
        statusPara = true;
      } else if (typeof data.para != 'string' && data.para.tipo == 'Usuários' && data.para.valores.includes(this.username)) {
        statusPara = true;
      } else if (typeof data.para != 'string' && data.para.tipo == 'Perfil' && data.para.valores.includes(this.perfil)) {
        statusPara = true;
      }

      if (statusPara) {
        this.updSync({ type: 'notifications', data })
      }
    });
  }

  stopSync() {
    this.socket.disconnect();
    this.reason = 'self';
    this.inactivityService.stopMonitoring();
  }
}
