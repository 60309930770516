<div class="system" *ngFor="let system of menu">
  <div class="title">
    {{system.nome}}
  </div>
  <div class="modules">
    <div class="module" *ngFor="let module of system.list" (click)="click(module,system.name)"  (contextmenu)="onRightClick($event,system.nome+'&'+module.click,'icon')">
      <div class="icon">
        <i *ngIf="module.icone" [ngClass]="'fa-thin fa-'+(module.icone)"></i>
        <img *ngIf="!module.icone" [attr.src]="'assets/svg/bell.svg'" alt="">
      </div>
      <div class="title">
        {{module.name}}
      </div>
    </div>
    <app-context-menu></app-context-menu>
  </div>
</div>
