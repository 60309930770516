
<div class="menu-background">
  <div *ngFor="let menuItem of menu" class="menu-item">
    <div class="expand-menu" >
      <div class="menu-icon" [attr.title]="menuItem.name" (click)="clickMenu(menuItem)" (mouseenter)="clear(menuItem)" [ngStyle]="menuItem.showList ? {'background':'var(--secondary)', 'border-radius': '6px'} : {}">
        <img *ngIf="menuItem.icon == 'bell'" [attr.src]="'assets/svg/bell.svg'" alt="">
        <i *ngIf="menuItem.icon != 'bell'" [ngClass]="'fa-thin fa-'+(menuItem.icon)"></i>
      </div>
      <div class="menu-list" [ngClass]="{'showList':menuItem.showList}">
        <div class="menu-pointer"></div>
        <div class="menu-list-title">{{menuItem.nome}}</div>
        <div *ngFor="let item of menuItem.list" class="menu-list-item clicavel" (contextmenu)="onRightClick($event,menuItem.nome+'&'+item.click,'text')">
          <div class="menu-text" (click)="click(item,menuItem.name)" >{{item.name}}</div>
          <!-- Terceiro nivel, não usa -->
          <div *ngIf="!item.click" (click)="click(item,menuItem.name)" class="menu-drop-icon"> <i class="fa fa-angle-right"></i> </div>
          <div *ngFor="let subItem of item.list" class="menu-list-subitem clicavel" [ngClass]="{'showList':item.showList}"> {{subItem.name}} </div>
        </div>
      </div>
    </div>
  </div>
  <app-context-menu></app-context-menu>
</div>
