<div class="login-background {{clientSelected}}">

  <div class="loading" [ngClass]="{'show-loading':loading}" *ngIf="loading">
    <div class="loading-title">{{loadingMsg || 'Carregando...'}}</div>
    <img src="assets/loading-mod.gif" alt="">
  </div>

  <div class="header-lang" (click)="showLang = !showLang" *ngIf="clientSelected.toLowerCase().includes('sophia')">
    <div class="lang-item-avatar-texto">
      <div class="lang-item-avatar">
        <img [src]="'../../../assets/images/bandeiras/'+language.lang+'.png'" class="lang-item-avatar" alt="">
      </div>
      <span class="item">{{language.title.toUpperCase()}}</span>
      <div>
        <img src="assets/svg/icon-arrow-down.svg" alt="">
      </div>
    </div>
    <div class="header-notif-drop-lang" [hidden]="!showLang">
      <div class="menu-pointer-lang"></div>
      <div class="header-conteudo-lang">
        <div *ngFor="let item of languages" class="header-notif-item-lang" (click)="clickLang(item)">
          <div class="notif-item-content">
            <div class="lang-item-avatar">
              <img [src]="'../../../assets/images/bandeiras/'+item.lang+'.png'" class="lang-item-avatar" alt="">
            </div>
            <div class="notif-item-text">
              <div class="notif-item-title-lang">
                {{item.title.toUpperCase()}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="['login','recover','mfa','newpass','confirm','signup'].includes(showBox) && isSophia" class="card-box">
    <img src="assets/images/box.png" loading="lazy" alt="Logo"
        style="min-height: 100%;">
  </div>

  <div *ngIf="showBox=='login'" class="login-container login-box">
    <div *ngIf="showBox=='login'&&!prelogo" class="login-imagem">
      <img src="{{clienteObj?.tema?.logo}}" loading="lazy" onError="this.src='assets/images/logo_black.png'" alt="Logo"
        style="max-width: 300px;">
    </div>
    <div *ngIf="showBox=='login'&&prelogo" class="login-imagem">
      <img src="{{clienteObj?.tema?.logo}}" loading="lazy" onError="this.src='assets/images/blstech2.png'" alt="Logo"
        style="max-width: 300px;">
    </div>
    <div *ngIf="!!clientSelected"
      [ngClass]="'login-status '+(status?'': statusText == 'Conectando...'? 'login-warning':  'login-danger')">
      {{statusText}}</div>
    <div class="version">v{{versionDefault}}</div>
    <div class="login-form-item" *ngIf="multi">
      <div class="login-input-text">{{language?.msg?.login?.title_sistema || 'Sistema'}}</div>
      <div class="login-input-item">
        <select type="text" [(ngModel)]="clientSelected" name="" value="" (ngModelChange)="setClient($event)">
          <option *ngFor="let cliente of clientes" [attr.value]="cliente.value">{{cliente.produto}}</option>
        </select>
      </div>
    </div>
    <div class="login-form-item">
      <div class="login-input-text">{{language?.msg?.login?.title_login || 'Login-1'}}</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="username" name="" value="" (keyup.enter)="login()">
      </div>
    </div>
    <div class="login-form-item {{clientSelected}}">
      <div class="login-input-text">{{language?.msg?.login?.title_senha || 'Senha-1'}}</div>
      <div class="login-input-item"><input [(ngModel)]="password" [attr.type]="showPass?'text':'password'" name=""
          value="" (keyup.enter)="login()"><i *ngIf="!remember" [ngClass]="'far fa-'+(showPass?'eye':'eye-slash')"
          (click)="togglePassword()"></i></div>
    </div>
    <div class="login-form-item {{clientSelected}}">
      <div class="login-input-item"><input [(ngModel)]="remember" type="checkbox" (ngModelChange)="clearPass()"
          style="margin-right: 5px;">{{language?.msg?.login?.title_lembrar_senha || 'Lembrar senha-1'}}</div>
    </div>
    <div class="login-form-item" (click)="login()">
      <div class="login-button">{{language?.msg?.login?.btn_entrar || 'ENTRAR-1'}}</div>
    </div>
    <div class="login-form-item" (click)="signup()" *ngIf="produto">
      <div class="signup-button">{{language?.msg?.login?.btn_signup || 'CADASTRA-SE'}} </div>
    </div>
    <div class="login-form-item login-lostpass" (click)="forgetPassword()">{{language?.msg?.login?.btn_esqueci_senha ||
      'Esqueci minha senha-1'}}</div>
  </div>

  <div *ngIf="showBox=='recover'" class="login-container login-recover">
    <div class="login-form-item login-info login-info-first">
      Digite seu e-mail abaixo e clique em Enviar. Nós lhe enviaremos uma email com link para uma página onde você
      poderá criar uma nova senha.
    </div>
    <div class="login-form-item">
      <div class="login-input-text">E-mail</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="emailSenha" name="" value="email"></div>
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="sendMail()">
        ENVIAR
      </div>
    </div>
    <div class="login-form-item login-lostpass" (click)="backLogin()">
      voltar ao login
    </div>
  </div>

  <div *ngIf="showBox=='signup'" class="login-container signup-box">
    <div class="login-form-item">
      <div class="login-input-text">Nome Completo</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="signData.nome" placeholder="Nome completo"></div>
    </div>
    <div class="login-form-item">
      <div class="login-input-text">E-mail</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="signData.email" placeholder="Email"></div>
    </div>
    <div class="login-form-item">
      <div class="login-input-text">CPF</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="signData.cpf" placeholder="CPF"></div>
    </div>
    <div class="login-form-item">
      <div class="login-input-text">Telefone</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="signData.telefone" placeholder="Telefone"></div>
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="signupEmail()">
        Criar Conta
      </div>
    </div>
    <div class="login-form-item login-lostpass" (click)="backLogin()">
      voltar ao login
    </div>
  </div>

  <div *ngIf="showBox=='mfa'" class="login-container login-recover">
    <div class="login-form-item login-info login-info-first">
      Verificação em 2 etapas foi enviado um código no seu email {{this.username}}.
    </div>
    <div class="login-form-item">
      <div class="login-input-text">Código de Autorização</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="mfaCodigo" name="" value="email"></div>
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="mfaCheck()">
        Autorizar
      </div>
    </div>
    <div class="login-form-item login-lostpass" (click)="backLogin()">
      voltar ao login
    </div>
  </div>

  <div *ngIf="showBox=='newpass'" class="login-container login-newpass">
    <div class="login-form-item login-title">
      nova senha
    </div>
    <div class="login-form-item login-info">
      Digite sua nova senha. Ela deve conter no mínimo<br>8 caracteres sendo 1 letra maiúscula, 1 letra minúscula, 1
      número e um símbolo.
    </div>
    <div class="login-form-item">
      <div class="login-input-text">Nova senha</div>
      <div class="login-input-item"><input [(ngModel)]="password1" [attr.type]="showPass?'text':'password'" name=""
          value=""><i [ngClass]="'far fa-'+(showPass?'eye':'eye-slash')" (click)="togglePassword()"></i></div>
      <div class="login-input-item login-t10"><input [(ngModel)]="password2" [attr.type]="showPass?'text':'password'"
          name="" value=""><i [ngClass]="'far fa-'+(showPass?'eye':'eye-slash')" (click)="togglePassword()"></i></div>
      <div *ngIf="checkPass()" class="login-input-text login-t10">As senhas não conferem</div>
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="sendNewPassword()">
        Enviar
      </div>
    </div>
  </div>


  <div *ngIf="showBox=='confirmemail'" class="login-container login-newpass">
    <div class="login-form-item login-title">
      nova senha
    </div>
    <div class="login-form-item login-info">
      Digite sua nova senha. Ela deve conter no mínimo<br>8 caracteres sendo 1 letra maiúscula, 1 letra minúscula, 1
      número e um símbolo.
    </div>
    <div class="login-form-item">
      <div class="login-input-text">Nova senha</div>
      <div class="login-input-item"><input [(ngModel)]="password1" [attr.type]="showPass?'text':'password'" name=""
          value=""><i [ngClass]="'far fa-'+(showPass?'eye':'eye-slash')" (click)="togglePassword()"></i></div>
      <div class="login-input-item login-t10"><input [(ngModel)]="password2" [attr.type]="showPass?'text':'password'"
          name="" value=""><i [ngClass]="'far fa-'+(showPass?'eye':'eye-slash')" (click)="togglePassword()"></i></div>
      <div *ngIf="checkPass()" class="login-input-text login-t10">As senhas não conferem</div>
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="confirmPassword()">
        Continuar
      </div>
    </div>
  </div>

  <div *ngIf="showBox=='confirm'" class="login-container login-confirm">
    <div class="login-form-item login-icon">
      <i class="fa fa-check"></i>
    </div>
    <div class="login-form-item login-title2">
      NOVA senha criada COM SUCESSO!
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="backLogin()">
        FAZER LOGIN
      </div>
    </div>
  </div>

  <div *ngIf="showBox=='checkout'" class="checkout login-container checkout-box">
        <h2>Checkout</h2>
        
        <h3>Dados do Ambiente</h3>
        <div class="input-group">
          <label>Nome do Ambiente</label>
          <input type="text" [(ngModel)]="checkout.space.nome" placeholder="Nome completo" required>
        </div>
        <div class="input-group">
          <label>Subdominio</label>
          <input type="text" [(ngModel)]="checkout.space.Subdominio" placeholder="Subdominio" required>
        </div>

        <h3>Dados do Cartão</h3>
        <div class="input-group">
            <label>Nome no Cartão</label>
            <input type="text" placeholder="Nome completo" required>
        </div>
        <div class="input-group">
            <label>Número do Cartão</label>
            <input type="text" placeholder="1234 5678 9012 3456" required>
        </div>
        <div class="input-group">
            <label>CPF</label>
            <input type="text" placeholder="000.000.000-00" required>
        </div>
        <div class="input-group">
            <label>Validade</label>
            <input type="text" placeholder="MM/AA" required>
        </div>
        <div class="input-group">
            <label>CVV</label>
            <input type="text" placeholder="123" required>
        </div>

        <div *ngIf="plano === 'standard'" class="free-trial-box">
          <p><strong>Atenção:</strong> Você terá 7 dias gratuitos no plano Standard. A cobrança será realizada apenas após esse período ou se você escolher outro plano. O plano pode ser cancelado a qualquer momento.</p>
        </div>
        
        <h3>Escolha um Plano</h3>
        <div class="plan" [ngClass]="{'selected':plano=='basico'}" (click)="selectPlan('basico')">
            <strong>Básico - R$ 29,90/mês</strong>
            <p>✔ 1 Usuário | ✔ 10 Formulários | ✔ 5 Integrações | ✔ 10GB</p>
        </div>
        <div class="plan" [ngClass]="{'selected':plano=='standard'}" (click)="selectPlan('standard')">
            <strong>Standard - R$ 59,90/mês  <span class="destaque">(Recomendado)</span></strong>
            <p>✔ 5 Usuários | ✔ 50 Formulários | ✔ 20 Integrações | ✔ Dashboards | ✔ 50GB</p>
        </div>
        <div class="plan" [ngClass]="{'selected':plano=='premium'}" (click)="selectPlan('premium')">
            <strong>Premium - R$ 99,90/mês</strong>
            <p>✔ Usuários Ilimitados | ✔ Formulários Ilimitados | ✔ Integrações Ilimitadas | ✔ Dashboards | ✔ 1TB</p>
        </div>
        
        <button class="checkout-btn" (click)="finish()">Finalizar Compra</button>
    </div>

  <div *ngIf="showBox=='signupmsg'" class="login-container signupmsg-box">
    <div class="login-form-item login-icon">
      <i class="fa fa-check"></i>
    </div>
    <div class="login-form-item login-title3">
      {{signupmsg}}
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="backLogin()">
        Login
      </div>
    </div>
  </div>
</div>