<div class="header-background">
  <div class="header-logo" (click)="goToHome()">
    <img src="{{clienteObj.tema?.logoHeader||clienteObj.tema?.logo}}" loading="lazy" onError="this.src='assets/svg/logo-icon.svg'" alt="Logo">
  </div>

  <div class="header-actions">
    <div class="header-wifi wifi-{{statusColor}}" *ngIf="!hideStatus && false">
      <i class="fa-solid fa-{{statusIcon}}"></i>
      <span class="header-wifi-text">{{statusText}}</span>
    </div>

    <div class="header-notifications noselect" style="cursor: pointer;" (click)="toggleNot()" [ngStyle]="{width: showNotify ? '384px' : ''}">
      <div style="display: flex; gap:10px; padding-left: 10px;width: 100%;">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" [attr.fill]="showNotify ? 'var(--secondary)':'var(--primary)'" viewBox="0 0 256 256">
          <path d="M220.07,176.94C214.41,167.2,206,139.73,206,104a78,78,0,1,0-156,0c0,35.74-8.42,63.2-14.08,72.94A14,14,0,0,0,48,198H90.48a38,38,0,0,0,75,0H208a14,14,0,0,0,12.06-21.06ZM128,218a26,26,0,0,1-25.29-20h50.58A26,26,0,0,1,128,218Zm81.71-33a1.9,1.9,0,0,1-1.7,1H48a1.9,1.9,0,0,1-1.7-1,2,2,0,0,1,0-2C53.87,170,62,139.69,62,104a66,66,0,1,1,132,0c0,35.68,8.14,65.95,15.71,79A2,2,0,0,1,209.71,185Z">
          </path>
        </svg>
        <span *ngIf="getNotReaded() > 0" class="notlength">{{getNotReaded() > 9 ? '+' : getNotReaded()}}</span>

        <div class="header-notif" *ngIf="showNotify">
          <div class="header-notif-title">{{language.msg.notificacoes}}</div>
          <i class="fa-regular fa-chevron-up"></i>
        </div>
      </div>

      <div class="header-notif-drop" [hidden]="!showNotify">
        <div class="notif-semnot" *ngIf="notifications.length == 0 ">{{language.msg.semNotificacoes}}</div>

        <div *ngFor="let item of notifications" class="header-notif-item" (click)="clickNotify(item)">
          <div class="notif-item-content">
            <div class="notif-item-avatar">
              <img *ngIf="item.icon && item.icon.toLowerCase() == 'applay'; else second" src="assets/svg/logo-icon.svg">

              <ng-template #second>
                <span *ngIf="item.new" class="header-notif-noread"></span>
                <span *ngIf="!item.icon.includes('fa-')" style="font-size: 23px;">{{iniciais(item.icon)}}</span>
                <i *ngIf="item.icon.includes('fa-')" [ngClass]="'fa fa-thin' + item.icon"></i>
              </ng-template>

            </div>
            <div class="notif-item-texto">
              <div class="notif-item-text">
                <div class="notif-item-title">{{item.title}}</div>
                <div class="notif-item-time">{{item.dataText}}</div>
              </div>
              <div class="notif-item-msg">{{item.msg}}</div>
            </div>
          </div>
        </div>

        <div class="header-notif-readed" *ngIf="notifications.length > 0" (click)="readAllNotify()">{{language.msg.marcarLidas}}</div>
      </div>
    </div>

    <div class="header-lang" *ngIf="showIconLang" (click)="showLang = !showLang">
      <div class="noselect lang-item-avatar-texto" style="font-size: 14px;">
        <div class="lang-item-avatar" style="margin-left: 3px;">
          <img [src]="'../../../assets/images/bandeiras/'+language.lang+'.png'" class="lang-item-avatar" alt="">
        </div>
        {{language.title.toUpperCase()}}
        <i *ngIf="showLang" class="fa-regular fa-chevron-up"></i>
        <i *ngIf="!showLang" class="fa-regular fa-chevron-down"></i>
      </div>
      <div class="header-notif-drop-lang" [hidden]="!showLang">
        <!-- <div class="menu-pointer-lang"></div> -->
        <div class="header-conteudo-lang">
          <div *ngFor="let item of languages" class="header-notif-item-lang" (click)="clickLang(item)">
            <div class="notif-item-content">
              <div class="lang-item-avatar">
                <img [src]="'../../../assets/images/bandeiras/'+item.lang+'.png'" class="lang-item-avatar" alt="">
              </div>
              <div class="notif-item-text">
                <div class="notif-item-title-lang">
                  {{item.title.toUpperCase()}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header-user">
      <div style="display: flex;align-items: center;gap: 10px;" (click)="showUser = !showUser">
        <div class="header-user-avatar">
          <!-- <img src="assets/images/avatar.png" alt=""> -->
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa-TgWv7NHUR4z4DpfdW9X1F51OLO5J6sQtM7l3iYoDg&s" alt="">

        </div>

        <div class="header-user-infos noselect">
          <div class="header-user-name">{{userInfo.name}}</div>
          <div class="header-user-cliente">{{userInfo.proprietario || userInfo.cargo}}</div>
        </div>
      </div>

      <div class="header-user-menu" *ngIf="showUser">
        <div class="header-user-menu-item" (click)="sair()">
          <i class="fa-regular fa-arrow-right-from-bracket"></i>
          {{language.msg.sair}}
        </div>
      </div>
    </div>
  </div>
</div>