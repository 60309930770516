import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { ModsService } from '../../services/mods.service';
import { SyncService } from '../../services/sync.service';
import { LangService } from '../../services/lang.service';
import { ContextMenuComponent } from '../../components/context-menu/context-menu.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  subscription: Subscription;
  menu: any = [];

  @ViewChild(ContextMenuComponent) contextMenu!: ContextMenuComponent;



  constructor(
    private user: UserService,
    private mods: ModsService,
    private sync: SyncService,
    private lang: LangService,
    private route: ActivatedRoute,
    private location: Location,
    private eRef: ElementRef
  ) {
    this.menu = [];
  }

  onRightClick(event: MouseEvent, click: any, type: any): void {
    let url = window.location.origin + '/module/' + click;
    this.contextMenu.openMenu(event, url, type); // Chama o método para abrir o menu
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.clear()
    }
  }

  processLang(menu) {
    let lang = this.lang.getLang().lang
    for (var cat of menu) {
      cat.nome = cat.lang?.[lang] || cat.nome;
      for (var mod of cat.list) {
        mod.name = mod.lang?.[lang] || mod.name;
      }
    }
    return menu;
  }

  ngOnInit(): void {
    this.user.getMenu((result) => {
      this.menu = this.processLang(result.data);
      let module = this.route.snapshot.paramMap.get('module');
      if (module) {
        let item = this.menu.find(e => e.nome == module.split('&')[0]).list.find(e => e.click == module.split('&')[1])
        this.click(item, module.split('-')[0]);
      } else {
        this.click(this.menu[0].list[0], this.menu[0].name);
      }
    })

    this.subscription = this.sync.listenSync.subscribe(data => {
      if (data.type == 'menu') {
        this.menu = this.processLang(this.menu);
      }
    });
  }

  clickMenu(menuItem) {
    if (menuItem.showList) {
      menuItem.showList = false;
    } else {
      this.menu.forEach(e => e.showList = false);
      menuItem.showList = true;
    }
  }

  clear(menuItem?) {
    if (menuItem && menuItem.showList) {
      this.menu.forEach(e => e.showList = false);
      menuItem.showList = true;
    } else {
      this.menu.forEach(e => e.showList = false);
    }
  }


  click(item, systemName) {
    if (item.click) {
      this.clear();
      this.mods.getMod(item.click, systemName);
    } else {
      item.showList = !item.showList;
    }
  }

}
