import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form/form.component';
import { TableComponent } from './table/table.component';
import { TitleComponent } from './title/title.component';
import { NavComponent } from './nav/nav.component';
import { ProgressComponent } from './progress/progress.component';
import { StepComponent } from './step/step.component';
import { CollapseComponent } from './collapse/collapse.component';
import { DashComponent } from './dash/dash.component';
import { DashModule } from './dash/dash.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ElementsModule } from '../elements/elements.module';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { TextComponent } from './text/text.component';
import { ModulesComponent } from './modules/modules.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { DashiaComponent } from './dashia/dashia.component';
import { IaComponent } from './ia/ia.component';
import { TabledashComponent } from './tabledash/tabledash.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';

@NgModule({
  declarations: [FormComponent, TableComponent, TitleComponent, NavComponent, ProgressComponent, StepComponent, CollapseComponent, DashComponent, TextComponent, ModulesComponent, DashiaComponent, IaComponent, TabledashComponent,ContextMenuComponent],
  imports: [
    FormsModule,
    CommonModule,
    DashModule,
    NgxDatatableModule,
    ElementsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule
  ],
  exports: [FormComponent, TableComponent, TabledashComponent, TitleComponent, NavComponent, ProgressComponent, StepComponent, CollapseComponent, DashComponent, TextComponent, ModulesComponent, DashiaComponent, IaComponent,ContextMenuComponent],
  entryComponents: [FormComponent, TableComponent, TabledashComponent, TitleComponent, NavComponent, ProgressComponent, StepComponent, CollapseComponent, DashComponent, TextComponent, ModulesComponent, DashiaComponent, IaComponent,ContextMenuComponent]
})

export class ComponentsModule { }
