import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { SyncService } from '../../services/sync.service';
import { ModsService } from '../../services/mods.service';
import { LangService } from '../../services/lang.service';
import { ContextMenuComponent } from '../context-menu/context-menu.component';


@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit {
  @ViewChild(ContextMenuComponent) contextMenu!: ContextMenuComponent;

  subscription: Subscription;
  @Input() public comp: any;
  menu:any=[];

  constructor(
    private user: UserService,
    private sync: SyncService,
    private lang: LangService,
    private mods:ModsService
  ) { }

  onRightClick(event: MouseEvent,click:any,type:any): void {
    let url = window.location.origin+'/module/'+click;
    this.contextMenu.openMenu(event,url,type); // Chama o método para abrir o menu
  }

  processLang(menu) {
    let lang = this.lang.getLang().lang
    for (var cat of menu) {
      cat.nome = cat.lang?.[lang] || cat.nome;
      for (var mod of cat.list) {
        mod.name = mod.lang?.[lang] || mod.name;
      }
    }
    return menu;
  }

  ngOnInit(): void {
    this.user.getMenu(result => this.menu = this.processLang(result.data))

    this.subscription = this.sync.listenSync.subscribe(data => {
      if (data.type == 'menu') {
        this.menu = this.processLang(this.menu);
      }
    });
  }

  click(item,systemName){
    this.mods.getMod(item.click,systemName);
  }

}
