<div class="content-all-chat-tittle" *ngIf="!isLocalOrIsChat">
  <div class="title-chat-ia" *ngIf="!isLocalOrIsChat">

    <div class="home-title" (click)="click($event,home.click, true)"
      [ngStyle]="{'color': isLocalOrIsChat?'#9F68F9': '#D9D9D9'}">
      <span><i class="fas fa-home"></i></span>
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="31" viewBox="0 0 54 31" fill="none">
      <path d="M24.1924 0H14.8777L0 15.0819L14.8777 30.1638H24.1924L9.25091 15.0819L24.1924 0Z"
        fill="url(#paint0_linear_432_3856)" />
      <path d="M29.0645 0H38.3792L53.2569 15.0819L38.3792 30.1638H29.0645L44.006 15.0819L29.0645 0Z"
        fill="url(#paint1_linear_432_3856)" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M38.1776 0H29.0557L25.5641 7.75611C25.7296 7.74503 25.8966 7.7394 26.0649 7.7394C28.9762 7.7394 31.4934 9.42321 32.6921 11.8682L38.1776 0ZM27.9045 22.2267C27.3165 22.3773 26.7001 22.4576 26.0649 22.4576C23.6919 22.4576 21.5806 21.3386 20.2318 19.6007L15.4766 30.1638H24.2359L27.9045 22.2267Z"
        fill="url(#paint2_linear_432_3856)" />
      <path
        d="M31.8457 5.70761H36.5209V24.4549H31.8457V22.4733C30.929 23.3463 30.0064 23.9782 29.0784 24.3688C28.1617 24.7478 27.1648 24.9374 26.0876 24.9374C23.6698 24.9374 21.5786 24.0011 19.814 22.1287C18.0492 20.2448 17.167 17.9071 17.167 15.1156C17.167 12.2209 18.0207 9.84877 19.728 7.99928C21.4354 6.1499 23.5093 5.2251 25.9501 5.2251C27.073 5.2251 28.1273 5.43765 29.1127 5.86264C30.0983 6.28777 31.0091 6.92531 31.8457 7.77531V5.70761ZM26.9127 9.5674C25.4574 9.5674 24.2485 10.0843 23.286 11.1181C22.3233 12.1404 21.8422 13.4558 21.8422 15.064C21.8422 16.6838 22.3291 18.0163 23.3032 19.0615C24.2887 20.107 25.4975 20.6296 26.9299 20.6296C28.408 20.6296 29.6342 20.1184 30.608 19.096C31.5822 18.0622 32.0692 16.7125 32.0692 15.0468C32.0692 13.4156 31.5822 12.0946 30.608 11.0836C29.6342 10.0727 28.4023 9.5674 26.9127 9.5674Z"
        fill="#1E1E1E" />
      <defs>
        <linearGradient id="paint0_linear_432_3856" x1="12.0962" y1="0" x2="12.0962" y2="30.1638"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#7000FF" />
          <stop offset="1" stop-color="#9F68F9" />
        </linearGradient>
        <linearGradient id="paint1_linear_432_3856" x1="41.1607" y1="0" x2="41.1607" y2="30.1638"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#7000FF" />
          <stop offset="1" stop-color="#9F68F9" />
        </linearGradient>
        <linearGradient id="paint2_linear_432_3856" x1="26.8271" y1="0" x2="26.8271" y2="30.1638"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#9F68F9" />
          <stop offset="1" stop-color="#7000FF" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
    <div class="title-ia">Inteligência Artificial</div>
  </div>

  <div class="content-chatia" *ngIf="!isLocalOrIsChat">
    <div class="content-chat">
      <div class="hist">
        <div class="content-messages" *ngFor="let message of hist; index as i">
          <div class="content-message"
            [ngStyle]="{ 'justify-content': histMapWhoIs[i]?.chatIA? 'flex-start' : 'flex-end' }">
            <div class="message" [ngClass]="{ 'message-ia': histMapWhoIs[i]?.chatIA }">
              <!-- <div class="message-text" >
                {{message}}
              </div> -->
              <div class="message-text" [innerHTML]="message">
              </div>
              <div class="icon">
                <div class="svg" *ngIf="histMapWhoIs[i]?.chatIA">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path
                      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                      fill="#FCFCFC" />
                    <path
                      d="M12.3628 12.4199C11.9196 12.4094 11.4793 12.4937 11.0714 12.6673C10.6635 12.8408 10.2974 13.0996 9.99768 13.4262C9.35593 14.1213 9.01588 15.0425 9.05206 15.9879C9.0171 16.9447 9.35971 17.8769 10.006 18.5834C10.3061 18.911 10.6724 19.1712 11.0806 19.3467C11.4887 19.5222 11.9296 19.609 12.3738 19.6014C12.8216 19.6138 13.267 19.5315 13.6808 19.36C14.0947 19.1885 14.4677 18.9316 14.7755 18.6061C15.4288 17.8904 15.7718 16.945 15.7294 15.9768C15.7495 15.506 15.6755 15.0359 15.5117 14.594C15.3479 14.1522 15.0976 13.7474 14.7755 13.4034C14.4642 13.0803 14.0887 12.8259 13.6732 12.6565C13.2577 12.4871 12.8113 12.4065 12.3628 12.4199ZM15.5845 9.91408H18.638V22.0879H15.5845V20.8015C15.0611 21.3178 14.4484 21.7347 13.7761 22.032C13.1554 22.2827 12.4914 22.4084 11.822 22.4017C11.0487 22.4053 10.2834 22.2444 9.57681 21.9299C8.87026 21.6154 8.23859 21.1544 7.72363 20.5774C6.56906 19.347 5.94839 17.7096 5.99722 16.0231C5.93045 14.3235 6.53042 12.6652 7.66914 11.4018C8.17745 10.8272 8.80363 10.3688 9.50503 10.0579C10.2064 9.74702 10.9666 9.59093 11.7337 9.60025C12.4446 9.59456 13.149 9.73543 13.803 10.0141C14.4713 10.3092 15.0763 10.7308 15.5845 11.2556V9.91408Z"
                      fill="#00111C" />
                    <path
                      d="M15.5986 9.92029H19.6039L25.9998 16.001L19.6039 22.0817H15.5986L22.0214 16.001L15.5986 9.92029Z"
                      fill="#9F68F9" />
                  </svg>
                </div>
                <div class="icon-person" *ngIf="!histMapWhoIs[i]?.chatIA">

                </div>
              </div>
            </div>
          </div>

          <div class="message-list" *ngIf="histMapWhoIs[i]?.list">
            <button class="btn-reponse show-list" *ngIf="listIsTrue"
              (click)="click($event, comp.funcVerLista, false, i)">
              <span><i class="fas fa-list-alt"></i></span>
              Ver Lista
            </button>
          </div>

        </div>
        <svg *ngIf="loading" class="loading" xmlns="http://www.w3.org/2000/svg" width="41" height="41"
          viewBox="0 0 41 41" fill="none">
          <circle cx="20.3516" cy="20.6541" r="17.5" stroke="#9F68F9" stroke-width="5" />
          <path
            d="M40.3516 20.6526C40.3516 31.6983 31.3973 40.6526 20.3516 40.6526C20.3516 40.6526 20.3516 31.6983 20.3516 20.6526C20.3516 9.60689 20.3516 0.652588 20.3516 0.652588C31.3973 0.652588 40.3516 9.60689 40.3516 20.6526Z"
            fill="white" />
        </svg>
        <div *ngIf="loading && loadingMessage">

          <div class="content-message">
            <div class="message message-ia">
              <div class="message-text">
                {{loadingMessageText}}
              </div>
              <div class="icon">
                <div class="svg">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path
                      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                      fill="#FCFCFC" />
                    <path
                      d="M12.3628 12.4199C11.9196 12.4094 11.4793 12.4937 11.0714 12.6673C10.6635 12.8408 10.2974 13.0996 9.99768 13.4262C9.35593 14.1213 9.01588 15.0425 9.05206 15.9879C9.0171 16.9447 9.35971 17.8769 10.006 18.5834C10.3061 18.911 10.6724 19.1712 11.0806 19.3467C11.4887 19.5222 11.9296 19.609 12.3738 19.6014C12.8216 19.6138 13.267 19.5315 13.6808 19.36C14.0947 19.1885 14.4677 18.9316 14.7755 18.6061C15.4288 17.8904 15.7718 16.945 15.7294 15.9768C15.7495 15.506 15.6755 15.0359 15.5117 14.594C15.3479 14.1522 15.0976 13.7474 14.7755 13.4034C14.4642 13.0803 14.0887 12.8259 13.6732 12.6565C13.2577 12.4871 12.8113 12.4065 12.3628 12.4199ZM15.5845 9.91408H18.638V22.0879H15.5845V20.8015C15.0611 21.3178 14.4484 21.7347 13.7761 22.032C13.1554 22.2827 12.4914 22.4084 11.822 22.4017C11.0487 22.4053 10.2834 22.2444 9.57681 21.9299C8.87026 21.6154 8.23859 21.1544 7.72363 20.5774C6.56906 19.347 5.94839 17.7096 5.99722 16.0231C5.93045 14.3235 6.53042 12.6652 7.66914 11.4018C8.17745 10.8272 8.80363 10.3688 9.50503 10.0579C10.2064 9.74702 10.9666 9.59093 11.7337 9.60025C12.4446 9.59456 13.149 9.73543 13.803 10.0141C14.4713 10.3092 15.0763 10.7308 15.5845 11.2556V9.91408Z"
                      fill="#00111C" />
                    <path d="M15.5986 9.92029H19.6039L25.9998 16.001L19.6039 22.0817H15.5986L22.0214 16.001L15.5986 9.92029Z"
                      fill="#9F68F9" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="content-input-chat" class="content-input-chat" *ngIf="!isLocalOrIsChat">
        <img src="../../../assets/ia.gif"/>
        <div id="dot" class="dot"></div>

        <input id="input-chat-open" class="input-chat-open" type="text" [(ngModel)]="request"
          (keyup.enter)="click($event,'send')" [placeholder]="placeholder">
        <button id="btn-send-chat-open" class="btn-send-chat-open" (click)="click($event,'send')"><span><i
              class="fas fa-arrow-up"></i></span></button>
      </div>
    </div>

    <div class="content-opts">
      <div class="start-new-chat" (click)="startNewChat($event)">
        <span><i class="fas fa-comment-medical"></i></span>
        Iniciar Nova Conversa
      </div>

      <div class="content-step">
        <div class="step" [ngClass]="{'step-active': step == 'Sugestões'}" (click)="setStep('Sugestões')">Sugestões
        </div>
        <div class="step" [ngClass]="{'step-active': step == 'Chats Recentes'}" (click)="setStep('Chats Recentes')">
          Chats Recentes</div>
      </div>

      <div class="content-opts-step" *ngIf="step ==  'Sugestões' ">
        <div class="sugestion-title">
          Sugestões da inteligência artificial:
        </div>
        <div class="content-sugestions" *ngIf="true">
          <div class="sugestion" *ngFor="let sugestion of sugestions" (click)="setSugestion($event, sugestion)">
            {{sugestion}}
          </div>
        </div>
      </div>

      <div class="content-opts-step" *ngIf="step ==  'Chats Recentes' ">
        <div class="sugestion-title">
          Chats Recentes:
        </div>
        <div class="content-sugestions" *ngIf="true">
          <div class="sugestion" *ngFor="let histChat of histChats" (click)="setHistChat($event, histChat)">
            {{histChat.subject.text}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content-ia" id="content-ia" [ngClass]="{'open-content-ia': localChat}" *ngIf="isLocalOrIsChat">

  <div id="btn-close-local-chat" class="btn-close-local-chat" *ngIf="localChat"
    (click)="click($event,'close-local-chat', true)">
    <span><i class="fas fa-arrow-left"></i></span>
    <span class="text-open-chat">FECHAR I.A.</span>
  </div>

  <!-- <div class="content-element" *ngFor="let element of comp.elements"> -->
  <div class="home" *ngIf="!localChat" (click)="click($event,home.click, true)"
    [ngStyle]="{'color': isLocalOrIsChat?'#9F68F9': '#D9D9D9'}">
    <span><i class="fas fa-home"></i></span>
  </div>

  <div class="ia" [ngStyle]="{'width': localChat?'-webkit-fill-available':''}">
    <img src="../../../assets/ia.gif" (click)="click($event,'local-chat')" />
    <div *ngIf="localChat" id="dot" class="dot"></div>

    <div id="content-local-chat" [ngStyle]="{'width': localChat?'-webkit-fill-available':''}"
      [ngClass]="{'content-local-chat': localChat}" *ngIf="localChat">

      <div id="request" class="request">{{requested?'Você: ' + requested: ''}}</div>
      <div id="response" class="response">{{response}}</div>

      <svg *ngIf="loading" class="loading" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41"
        fill="none">
        <circle cx="20.3516" cy="20.6541" r="17.5" stroke="#9F68F9" stroke-width="5" />
        <path
          d="M40.3516 20.6526C40.3516 31.6983 31.3973 40.6526 20.3516 40.6526C20.3516 40.6526 20.3516 31.6983 20.3516 20.6526C20.3516 9.60689 20.3516 0.652588 20.3516 0.652588C31.3973 0.652588 40.3516 9.60689 40.3516 20.6526Z"
          fill="white" />
      </svg>

      <div id="content-input" class="content-input" *ngIf="localChat && !listIsTrue">
        <input id="input" class="input" type="text" [(ngModel)]="request" (keyup.enter)="click($event,'send')"
          [placeholder]="placeholder">
        <button id="btn-send" class="btn-send" (click)="click($event,'send')"><span><i
              class="fas fa-arrow-up"></i></span></button>
      </div>

      <div class="content-buttons-after-response">
        <button class="btn-reponse btn-back-request" *ngIf="listIsTrue" (click)="click($event, 'backRequest')">
          <span><i class="fas fa-chevron-circle-left"></i></span>
        </button>
        <button class="btn-reponse show-list" *ngIf="listIsTrue" (click)="click($event, comp.funcVerLista, true)">
          <span><i class="fas fa-list-alt"></i></span>
          Ver Lista
        </button>
        <button class="confirm" *ngIf="localChat && confirm"></button>
      </div>

    </div>
  </div>

  <div class="chat" *ngIf="!localChat" (click)="click($event,chat.click, false)"
    [ngStyle]="{'color': isLocalOrIsChat?'#D9D9D9': '#9F68F9'}">
    <span><i class="fas fa-comments"></i></span>
  </div>
  <!-- </div> -->

  <div id="btn-open-chat" class="btn-open-chat" *ngIf="localChat" (click)="click($event,chat.click, false)">
    <span><i class="fas fa-comments"></i></span>
    <span class="text-open-chat">VISÃO COMPLETA</span>
  </div>

  <div *ngIf="false" class="usuario"></div>
</div>