import { Injectable, NgZone } from '@angular/core';
import { fromEvent, merge, Observable, Subscription, timer } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private timeoutInMs =  20 * 1000; // 5 minutos
  private userActivity$: Observable<boolean>;
  private inactivityTimer$: Observable<boolean>;
  private subscription: Subscription | null = null;

  constructor(private ngZone: NgZone) {
    // Combina eventos de interação para monitorar a atividade
    this.userActivity$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'click'),
      fromEvent(window, 'keypress'),
      fromEvent(window, 'scroll'),
      fromEvent(window, 'touchstart')
    ).pipe(
      map(() => true) // Qualquer evento é considerado "atividade"
    );

    // Inicia o monitoramento
    this.inactivityTimer$ = this.userActivity$.pipe(
      switchMap(() =>
        timer(this.timeoutInMs).pipe(
          map(() => false) // Inatividade após o timeout
        )
      ),
      startWith(true) // Inicialmente ativo
    );
  }

  startMonitoring(time,callback: () => void): void {
    if(!time){return}
    this.timeoutInMs = time;
    console.log("🚀 ~ InactivityService ~ startMonitoring ~ segundos:", this.timeoutInMs/1000)
    // Monitorar dentro do NgZone para evitar problemas de detecção
    this.ngZone.runOutsideAngular(() => {
      this.subscription = this.inactivityTimer$.subscribe((active) => {
        if (!active) {
          this.ngZone.run(() => callback()); // Callback em caso de inatividade
        }
      });
    });
  }

  stopMonitoring(): void {
    this.subscription?.unsubscribe();
  }
}
