import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css']
})
export class ContextMenuComponent implements OnInit {

  isVisible = false;
  positionX = 0;
  positionY = 0;
  clickMenu = [
    {text:'Abrir em um nova Guia',url:'https://google.com'}
  ];

  constructor(
  ) { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = (event.target as HTMLElement).closest('.context-menu');
    if (!clickedInside) {
      this.closeMenu();
    }
  }

  ngOnInit(): void {
  }

  openMenu(event: MouseEvent,url:any, type:any): void {
    console.log('Openmenu',type);

    this.clickMenu = [
      {text:'Abrir em um nova Guia',url:url}
    ]
    
    event.preventDefault(); // Previne o menu padrão do navegador
    this.isVisible = true;
    this.positionX = type=='icon'?event.clientX-50:event.clientX;
    const scrollContainer = document.querySelector('.workspace-overflow') as HTMLElement;
    let scrollTop = scrollContainer && type=='icon' ? scrollContainer.scrollTop : 0;
    this.positionY = event.clientY+(scrollTop)-50;
  }

  closeMenu(): void {
    this.isVisible = false;
  }

  onOptionSelected(option: string): void {
    // alert(`${option} selecionada`);
    window.open(option,'_blank')
    this.closeMenu();
  }


}
