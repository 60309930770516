import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModsService } from '../../services/mods.service';
import { ToolsService } from '../../services/tools.service';
import { LangService } from '../../services/lang.service';
import { ApiService } from 'src/app/services/api.service';

declare var google: any;

const sleep = ms => new Promise(r => setTimeout(r, ms));

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.css']
})
export class WorkspaceComponent implements OnInit {

  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  modId: any = '';
  systemName: any = '';
  mod: any = {};
  layerId: any = '';
  layer: any = {
    params: {
      menu: []
    },
    components: []
  };
  modalId: any = '';
  modal: any = {};
  language: any = { msg: { caminho: 'Caminho' } };

  checkCompIA: any = false;
  blockClose: any = false;

  constructor(
    private mods: ModsService,
    private tools: ToolsService,
    private api:ApiService,
    private lang: LangService
  ) { }

  ngOnInit(): void {
    this.subscription = this.mods.listenMod.subscribe(data => {
      //CONFIGURATION IA COMPONENT
      this.modId = data.id;
      this.mod = data.mod;
      if (
        this.mod &&
        this.mod.layouts &&
        this.mod.layouts.map(e => e.components).flat().filter(e => ['chatia', 'ia'].includes(e.dataType)) &&
        this.mod.layouts.map(e => e.components).flat().filter(e => ['chatia', 'ia'].includes(e.dataType)).length > 0
      ) {
        this.checkCompIA = true
      } else {
        this.checkCompIA = false
      }
      this.systemName = data.system;
      this.initMod();
    });
    this.subscription2 = this.mods.updateLayout.subscribe(data => {
      if (this.systemName == data.system && this.modId == data.modId) {
        this.runLayout(data.pageId, data.optItem);
      }
    });
    this.subscription3 = this.mods.listenComponents.subscribe(async data => {
      if (!data.pageType) {
        data.pageType = this.modalId == '' ? 'layer' : 'modal';
      }
      let tools = this.tools.getTools(data.pageType);
      if (data.type == 'translate') {
        this.language = data.language;
        this.updAllComponentsLoad(this[data.pageType].components);
      }
      if (data.type == 'scope' && this[data.pageType].params) {
        this.updComponents(this[data.pageType].components, data.pageType, tools);
        this.updComponents(this[data.pageType].params.menu, data.pageType, tools);
        this.updParams(this[data.pageType].params, tools);
      } else if (data.type == 'load') {
        this.updComponentsLoad(this[data.pageType].components, data.table);
      } else if (data.type == 'exitModal') {
        this.exitModal();
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
    }, 15000)
  }

  updComponentsLoad(components, table) {
    components.forEach(e => {
      if (e.table && e.table == table && e.updateTable) {
        e.updateTable();
      }
    });
  }

  updAllComponentsLoad(components) {
    components.forEach(e => {
      if (e.table && e.updateTable) {
        e.updateTable(true);
      }
    });
  }

  updParams(params, tools) {
    if (params?.titleBind) {
      params.title = this.tools.nameParse(params.titleBind, params.title, tools.scope[(params.scope || 'item')])
    }
    if (params?.titleNewBind) {
      params.titleNew = this.tools.nameParse(params.titleNewBind, params.titleNew, tools.scope[(params.scope || 'item')])
    }
    if (this.mod.titleBind) {
      this.mod.title = this.tools.nameParse(this.mod.titleBind, this.mod.title, tools.scope[(params.scope || 'item')])
    }
  }

  copy(source) {
    let keys = Object.keys(source);
    let keysScope = Object.keys(source.scope);
    let newsource = { components: [], pageType: '', scope: {} };
    for (var key of keys) {
      if (key != 'scope') {
        newsource[key] = source[key];
      } else {
        newsource.scope = {};
      }
    }
    for (var key of keysScope) {
      newsource.scope[key] = { ...source.scope[key] };
    }
    return newsource;
  }

  updComponents(components, pageType, tools?, parent?) {
    if (!components) { return }

    components.forEach(async e => {
      e.pageType = pageType;

      if (e.dataType == 'map') {
        var divMap = document.getElementById(e.id);

        if (divMap && !e.map) {
          e.map = new google.maps.Map(divMap, {
            center: { lat: e.lat || -23.506563, lng: e.lng || -46.601148 },
            zoom: e.zoom || 8
          });
        }
      }

      if (e.elements) {
        this.updComponents(e.elements, pageType, tools, e);
      }
      if (e.header) {
        this.updComponents(e.header, pageType, tools, e);
        e.header.forEach(f => {
          if (f.changeShow) { e.changeTable = true; }
          delete f.changeShow
        })
      }
      if (e.components) {
        this.updComponents(e.components, pageType, tools, e);
      }
      if (e.data && e.dataType == 'text') {
        this.updComponents(e.data, pageType, tools, e);
      }
      if (e.table && e.updateTable && e.changeTable) {
        e.updateTable(true);
        e.changeTable = false
      }
      if (e.menuType == 'next') {
        if (typeof e.param == 'string') {
          e.param = { id: e.param }
        }
        let comp = this[pageType].components.find(f => f.id == e.param.id);
        // console.log(comp);

        if (!comp.show || comp.options.indexOf(comp.value) + 1 == comp.options.length) {
          e.show = false;
        } else {
          e.show = true;
        }
      }
      if (e.menuType == 'previus') {
        if (typeof e.param == 'string') {
          e.param = { id: e.param }
        }
        let comp = this[pageType].components.find(f => f.id == e.param.id);
        if (!comp.show || comp.options.indexOf(comp.value) == 0) {
          e.show = false;
        } else {
          e.show = true;
        }
      }
      if (tools && tools.scope) {
        if (e.dataType == 'form' && e.scopeField) {
          if (typeof e.scopeField == 'string') {
            e.scopeField = { model: e.scopeField, scope: (e.scope || 'item') }
          }
          e.scope = tools.scope[e.scopeField.scope][e.scopeField.model];
        }
        if (e.menuType != 'next' && e.menuType != 'previus') {
          if (!e.view) {
            e.show = true;
          } else if (e.field && e.name && e.show !== undefined) {
            let showOld = !!e.show;
            e.show = this.checkView(e.view, e.pageType, e.scope);
            if (showOld != e.show && e.field && e.name) {
              e.changeShow = true;
            }
          } else if (e.id == 'testView') {
            e.show = this.checkView(e.view, e.pageType, e.scope, e.id);
          } else {
            e.show = this.checkView(e.view, e.pageType, e.scope);
          }
        }
        if (e.modelBind || e.model) {
          var parts = (e.modelBind || e.model).split('.');
          var value = tools.scope[((!e.scope && parent ? parent : e).scope || 'item')];
          for (var part of parts) {
            if (!value) {
              value = null;
            } else {
              value = value[part]
            }
          }
          e.value = value;

          if (e.modelBind) {
            e.bind = this.tools.nameParse(e.value || '', e.value || '', tools.scope[(e.scope || 'item')]);
          }
          if (e.elementType == 'datepicker' && e.value) {
            e.valueDatepicker = e.value.split('/').reverse().join('-')
          }
        }
        if (e.textBind) {
          if (e.textBind.yes) {
            e.text = {
              yes: this.tools.nameParse(e.textBind.yes, e.textBind.yes, tools.scope[((!e.scope && parent ? parent : e).scope || 'item')]),
              no: this.tools.nameParse(e.textBind.no, e.textBind.no, tools.scope[((!e.scope && parent ? parent : e).scope || 'item')])
            };
          } else {
            e.text = this.tools.nameParse(e.textBind, e.textBind, tools.scope[((!e.scope && parent ? parent : e).scope || 'item')]);
          }
        }
        if (e.nameBind) {
          e.name = this.tools.nameParse(e.nameBind, e.name, tools.scope[((!e.scope && parent ? parent : e).scope || 'item')]);
        }
        if (e.titleBind) {
          e.title = this.tools.nameParse(e.titleBind, e.title, tools.scope[((!e.scope && parent ? parent : e).scope || 'item')]);
        }
        if (e.colorBind) {
          e.color = this.tools.nameParse(e.colorBind, e.colorBind, tools.scope[((!e.scope && parent ? parent : e).scope || 'item')]);
        }
        if (e.optionsItem) {
          if (typeof e.optionsItem == 'string') {
            e.optionsItem = { model: e.optionsItem, scope: ((!e.scope && parent ? parent : e).scope || 'item') }
          }
          if (!e.optionsItem.scope) {
            e.optionsItem.scope = ((!e.scope && parent ? parent : e).scope || 'item');
          }
          if (Array.isArray(tools.scope[e.optionsItem.scope][e.optionsItem.model])) {
            if (tools.scope[e.optionsItem.scope][e.optionsItem.model]) {
              e.options = tools.scope[e.optionsItem.scope][e.optionsItem.model].filter(e => e).map(f => f[e.optionsItem.item] || f)
            }
          }
          if (e.optionsItem.itemBind) {
            e.optionsBind = tools.scope[e.optionsItem.scope][e.optionsItem.model].map(f => f[e.optionsItem.itemBind]);
          }
        }
        if (e.optionsLoad && tools.load[e.optionsLoad.table]) {
          e.options = tools.load[e.optionsLoad.table].map(f => f[e.optionsLoad.item]);
          if (e.optionsLoad.itemBind) {
            e.optionsBind = tools.load[e.optionsLoad.table].map(f => f[e.optionsLoad.itemBind]);
          }
        }
        if (e.optionsBind) {
          e.optionsObj = e.optionsBind.map((f, i) => ({
            value: f.value || (!e.options ? f : e.options[i]),
            view: this.tools.nameParse(f.view || f, f.view || f, tools.scope[(e.scope || 'item')])
          }));
          // console.log('valor', e.optionsObj.find(f => f.value == e.value));
          // e.bind = this.tools.nameParse(e.optionsObj.find(f => f.value == e.value)?.view||'', e.optionsObj.find(f => f.value == e.value)?.view||'', tools.scope[(e.scope || 'item')]);
        }
        if (e.optionsObj && !e.options) {
          e.options = e.optionsObj.map(f => f.value);
        }
      }
    });
  }

  checkView(view, pageType, scope?, test?) {
    return this.tools.checkView(view, pageType, scope, test);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
  }

  async exitModal() {
    console.log('exit modal');
    this.modalId = '';
    this.modal = {};
    let tools = this.tools.getTools('layer');
    this.updParams(this['layer'].params, tools);
    this.updComponents(this['layer'].components, 'layer', tools);
    this.updComponents(this['layer'].params.menu, 'layer', tools);

  }

  getChart(pageType, chartId) {
    let findComponents = (list, id) => {
      var dataResult = null;
      list.forEach(e => {
        if (e.id == id) { dataResult = e }
        if (e.charts) {
          let result = findComponents(e.charts, id);
          if (result) { dataResult = result; }
        }
      })
      return dataResult;
    };
    return findComponents(this[pageType].components, chartId);
  }

  initMod() {
    this.mods.updAlert({ type: 'loading', value: true });
    let layerBase = this.mod.layouts.find(e => e.pageId == this.mod.start);

    if (!layerBase && this.mod.layouts.length > 0) {
      layerBase = this.mod.layouts[0];
    }

    let layer = Object.assign({}, layerBase);

    this.updComponents(layer.components, layer.pageType);

    this.tools.startTools(layer, null, async (tools) => {
      this.layer = layer;
      this.layerId = this.layer.pageId;

      this.updComponents(this.layer.components, this.layer.pageType, tools);
      this.updComponents(this.layer.params.menu, this.layer.pageType, tools);
      this.updParams(this.layer.params, tools);
      this.updComponentsLoad(this.layer.components, true);

      if (this.layer.params.boot) {
        tools.funcs[this.layer.params.boot](tools, {
          boot: true
        })
      }
      this.mods.updAlert({ type: 'loading', value: false });

      let clientObj = this.api.getClientFull();
      window.document.title = clientObj.produto + ' - '+ (this.mod.title);
    });
  }

  getLayout(pageId) {
    let modLayoutBase = this.mod.layouts.find(e => e.pageId == pageId);
    let modLayout = this.copy(modLayoutBase);
    return modLayout;
  }

  runLayout(pageId, optItem) {
    this.mods.updAlert({ type: 'loading', value: true });
    let modLayout: any = this.getLayout(pageId);

    //CONFIGURATION BLS
    if (modLayout.blockClose) {
      //NÃO DEVE SER POSSIVEL FECHAR PELO X O MÓDULO
      this.blockClose = modLayout.blockClose
    }

    let type = modLayout.pageType;
    if (type == 'layer') {
      this.modal = {};
      this.modalId = '';
    }
    this.updComponents(modLayout.components, type);
    this.tools.startTools(modLayout, optItem, async (tools) => {
      this[type] = modLayout;
      this[type].params.itemNew = tools.itemNew;
      this[type + 'Id'] = this[type].pageId;
      this.updComponents(this[type].components, this[type].pageType, tools);
      this.updComponents(this[type].params.menu, this[type].pageType, tools);
      this.updParams(this[type].params, tools);

      for (var loadItem of this[type].params.load) {
        this.updComponentsLoad(this[type].components, loadItem.model);
      }
      if (this[type].params.boot) {
        this[type].funcs[this[type].params.boot](tools, {
          boot: true
        })
      }
      this.mods.updAlert({ type: 'loading', value: false });
    });
  }

  cmd(option, param?, pageType?) {
    if (option == 'modal' || option == 'layer' || option == 'layout') {
      this.mods.layout({ modId: this.modId, system: this.systemName, pageId: param.pageId || param.param, optItem: { itemId: param.itemId, base: param.base } });
    }
    if (option == 'close') {
      this.exitModal();
    }
    if (option == 'func') {
      this.tools.cmdFunc(param.pageType || pageType, param.param, { menuItem: param });
    }
    if (option == 'next') {
      let comp = this[param.pageType].components.find(e => e.id == param.param.id);
      let idx = comp.options.indexOf(comp.value);
      if (comp.options.length > idx + 1) {
        comp.old = comp.value + '';
        comp.value = comp.options[idx + 1];
        this.tools.setItem(comp.pageType, (comp.scope || 'item'), comp.model, comp.value);
      }
      if (param.param && param.param.click) {
        this.tools.cmdFunc(param.pageType, param.param.click, { menuItem: param, old: comp.old });
      }
    }
    if (option == 'previus') {
      let comp = this[param.pageType].components.find(e => e.id == param.param.id);
      let idx = comp.options.indexOf(comp.value);
      if (idx - 1 >= 0) {
        comp.old = comp.value + '';
        comp.value = comp.options[idx - 1];
        this.tools.setItem(comp.pageType, (comp.scope || 'item'), comp.model, comp.value);
      }
      if (param.param && param.param.click) {
        this.tools.cmdFunc(param.pageType, param.param.click, { menuItem: param, old: comp.old });
      }
    }
    if (option == 'save') {
      this.tools.cmdOptions(option, param);
    }
    if (option == 'delete') {
      this.tools.cmdOptions(option, param);
    }
  }
}
